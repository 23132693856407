.sol-contact,
.sol-related {
  background-color: @related-box-bg;
  margin-top: 5px;
  //padding-bottom: 8px;
 // padding-top: 10px;
  margin-bottom: 10px;
}
.sol-related {
  &-item {
    margin-bottom: 5px;
    text-decoration: none;
    line-height: 23px;
    display: inline-block;
    &-name {
      text-decoration: underline;
      color: @related-box-link-text;
      .transition(color);
    }
    &-size {
      display: inline-block;
      color: @related-box-link-text;
      font-size: 12px;
      margin-left: 10px;
    }
    & i.fa {
      width: 17px;
      height: 17px;
      margin-right: 5px;
      border-radius: 50%;
      color: @related-box-icon;
      font-size: 16px;
      line-height: 15px;
      text-align: center;
      position: relative;
      top: 1px;
      &.sol-link-icon {
        border: 1px solid @related-box-icon;
      }
    }
    &:hover &-name {
      color: @related-box-link-hover;
    }
  }
  div &-item &-item-name {
    text-decoration: none;
    color: @related-box-text;
  }
}
.sol-contact {
  &-item {
    margin-bottom: 15px;
    h3 {
      margin-top: 0;
      font-size: 15px;
      font-weight: 700;
    }
    h4 {
      margin-top: 10px;
      display: block;
      margin-bottom: 5px;
      font-weight: 700;
    }
    h5 {
      margin-top: 10px;
      display: block;
      margin-bottom: 5px;
      font-weight: 700;
    }
    p {
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 15px;
      line-height: 1.2;
    }
  }
}
