.sol-widget-decoration.sol-decoration-icon {
  background-color: transparent;
  box-shadow: none;
  text-align: center;   
  color: #532c6c;
  font-size: 72px;
  width: 75px;
  margin-right: auto;
  margin-left: auto;
  .hf;
  .hf-icon-Decoration;
}
