
.sol-top-search-field {
  position: relative;

  input[type=search],
  input[type=text] {
    .no-outline-focus;
    .no-appearance;
    .transition(border-color);
    width: 100%;
    height: 45px;
    padding: 0 11px;
    border: 2px solid @search-top-field-border;
    border-radius: 4px;
    background-color: @search-top-field-bg;
    color: @search-top-field-text;
    font-family: @main-font;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    @media @sm {
      height: 35px;
    }
    &:focus {
      border-color: @search-top-field-border-hover;
    }
  }

  button[type=submit] {
    .no-appearance;
    .focus-outline;
    .transition(background-color);
    position: absolute;
    top: -9px;
    right: -10px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: @search-top-button-bg;
    color: @search-top-button-text;
    font-size: 25px;
    text-align: center;
    text-decoration: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
    @media @sm {
      width: 50px;
      height: 50px;
      top: -8px;
    }

    & > i.fa {
      position: relative;
      top: -2px;
    }
  }
  & > div:hover {
    input[type=search],
    input[type=text] {
      border-color: @search-top-field-border-hover;
    }

    button {
      background-color: @search-top-button-hover;
    }
  }
}
