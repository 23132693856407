// BREAKPOINTS
@xs-min: 0;
@sm-min: 480px;
@md-min: 768px;
@lg-min: 992px;
@xl-min: 1200px;
@xs-max: (@sm-min - 1);
@sm-max: (@md-min - 1);
@md-max: (@lg-min - 1);
@lg-max: (@xl-min - 1);
@xs: ~'(max-width: @{xs-max})';
@sm: ~'(max-width: @{sm-max})';
@sm-strict: ~'(min-width: @{sm-min}) and (max-width: @{sm-max})';
@md: ~'(max-width: @{md-max})';
@md-strict: ~'(min-width: @{md-min}) and (max-width: @{md-max})';
@lg: ~'(max-width: @{lg-max})';
@lg-strict: ~'(min-width: @{lg-min}) and (max-width: @{lg-max})';
@xl: ~'(min-width: @{xl-min})';
@webkit-only: ~'screen and (-webkit-min-device-pixel-ratio:1.3)';
@grid-width: 1440px;
@grid-min: (@grid-width + 1);
@col-size: 12;
@col-spacing: 20px;
.generate-columns(@n; @selector; @i:1) when (@i =< @n) {
  @col: ~'@{selector}-@{i}';
  @push: ~'@{selector}-push-@{i}';
  @{col} {
    width: auto !important;
    flex: 1 0 floor(@i * 100% / @n);
    max-width: floor(@i * 100% / @n);
  }
  @{push} {
    position: relative;
    left: (@i * 100% / @n);
  }
  .generate-columns(@n, @selector, (@i + 1));
}
.sol-row(@col-class) {
  .sol-row,
  .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -(@col-spacing / 2);
    margin-right: -(@col-spacing / 2);
    justify-content: flex-start;
    @media @sm {
      margin-left: -(@col-spacing / 4);
      margin-right: -(@col-spacing / 4);
    }
    &.sol-row-reverse {
      flex-direction: row-reverse;
    }
    &.sol-row-center {
      justify-content: center;
    }
    .generate-columns(@col-size, ~  '*.@{col-class}');
  }
}
//Init grid
.sv-grid-main.sv-fluid-grid {
  padding-left: 45px;
  padding-right: 45px;
  @media @md {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media @sm {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media @xs {
    padding-left: 8px;
    padding-right: 8px;
  }
  @media @xl {
    .sol-row(sol-col-xl);
    .sol-row(sv-column);
  }
  @media @lg-strict {
    .sol-row(sol-col-lg);
    .sol-row(sv-column);
  }
  @media @md-strict {
    .sol-row(sol-col-md);
  }
  @media @sm-strict {
    .sol-row(sol-col-sm);
  }
  @media @xs {
    .sol-row(sol-col-xs);
  }
  & *[class*= ~'sol-col-'],
  & *[class*= ~'sv-column-'] {
    flex: 1 0 100%;
    max-width: 100%;
    padding-left: (@col-spacing / 2);
    padding-right: (@col-spacing / 2);
    @media @sm {
      padding-left: (@col-spacing / 4);
      padding-right: (@col-spacing / 4);
    }
  }
}
.sol-show-lg,
.sol-show-md,
.sol-show-sm,
.sol-show-xl,
.sol-show-xs {
  display: none;
}
.sol-hide-sm {
  display: inherit;
}
@media @xl {
  .sol-hide-xl {
    display: none;
  }
  .sol-show-xl {
    display: inherit;
  }
}
@media @lg {
  .sol-hide-lg {
    display: none;
  }
  .sol-show-lg {
    display: inherit;
  }
}
@media @md {
  .sol-hide-md {
    display: none;
  }
  .sol-show-md {
    display: inherit;
  }
}
@media @sm {
  .sol-hide-sm {
    display: none;
  }
  .sol-show-sm {
    display: inherit;
  }
}
@media @xs {
  .sol-hide-xs {
    display: none;
  }
  .sol-show-xs {
    display: inherit;
  }
}
