.sol-btn {
  .button;
}
.font-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  & > a {
    .button;
    display: inline;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.sol-btn-small {
  .button-small();
}
.sv-text-portlet.sol-font-btn-small {
  .font-btn {
    padding-top: 4px;
    //padding-bottom: 5px;
    margin-bottom: 0;
    & > a {
      .button-small;
    }
  }
}
